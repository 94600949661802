<template>
    <BaseSection
        width="page"
        class="section--partners"
    >
        <div class="section--partners__container">
            <h3>{{ label ? label : $t('partners.title') }}</h3>

            <a
                v-if="partner"
                :href="partner.partnerLink"
                class="partner-logo"
            >
                <img
                    v-if="partner.partnerLogo?.[0]"
                    :src="partner.partnerLogo[0].w160"
                    :srcset="`${partner.partnerLogo[0].w160} 160w`"
                    sizes="(max-width: 160px) 160px"
                    :height="partner.partnerLogo[0].height"
                    :width="partner.partnerLogo[0].width"
                    alt=""
                >
            </a>
        </div>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        default: null
    },
    partnerLogos: {
        type: Array,
        default: () => []
    }
});

const { partnerLogos } = toRefs(props);

const { image: partner, start: startFlipper, stop: stopFlipper } = useFlipper(partnerLogos.value, { interval: 2000 });

onMounted(() => {
    startFlipper();
});

onUnmounted(() => {
    stopFlipper();
});
</script>

<style lang="less">
.section--partners__container {
    text-align: center;

    h3 {
        .typography-text();
        .typography-small();
        margin-bottom: 1.25rem;
        text-align: center;
    }

    .partner-logo {
        display: block;
        height: 5rem;
        margin: 0 auto;

        img {
            height: 5rem;
            width: auto;
        }
    }

    @media @q-lg-min {
        width: 50%;
        display: flex;
        align-items: center;

        h3 {
            text-align: left;
            margin: 0;
            min-width: 13rem;
        }

        .partner-logo {
            display: block;
            height: 6rem;
            margin: 0 1.5rem;

            img {
                height: 6rem;
                width: auto;
            }
        }
    }
}
</style>
